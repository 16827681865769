import './App.css';

function App() {

  const gotoWallet = () => {

    const analytics = window.analytics
    //console.log('start exit procedures')
    // Log page analytics event
    if (typeof analytics !== 'undefined')
      analytics.track("loadWallet");

    var url = new URL(window.location.href)
    console.info("analytics", analytics)
    url.searchParams.append("anonymousId", analytics?.user().anonymousId())
    window.open(`https://wallet.vatom.com/b/lakefest${url.search}`, '_self', 'noopener,noreferrer')
  }


  return (
    <div className="App">
      <div className="hero">
        <p>10TH - 13TH<br></br>AUGUST 2023</p>
        <img className="logo" src={require("./lakefest.png")} alt="LakeFest 2023" />
      </div>
      <div className="body">
        <button className="cta" onClick={gotoWallet}>LET'S GO</button>
        <p>Sign up now for your FREE Lakefest Digital Lanyard for exclusive news, info & line-up, games, and the chance to win some incredible prizes!</p>

      </div>

    </div>
  );
}

export default App;
